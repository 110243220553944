import request from '@/router/axios';

export const alipay = (orderNo,appId,nonce,ts,code,data)=>{
  return request({
    url: '/api/zt-pay/ztpay/payMethod',
    method: 'post',
    params: {
      orderNo,
      appId,
      nonce,
      ts,
      code,
      data
    }
  })
}

export const getDetail = (itemId) => {
  return request({
    url: '/api/zt-pay/statistics/itemDetail',
    method: 'get',
    params: {
      itemId
    }
  })
}

export const  getOrder = ()=>{
  return request({
    url: '/api/zt-pay/ztpay/tradeOut',
    method: 'post'
  })
}

export const getVerifyPay =(outTradeNo,method)=>{
  return request({
    url: '/api/zt-pay/ztpay/verify',
    method: 'post',
    params: {
      outTradeNo,
      method
    }
  })
}

export const tradeAll =(outTradeNo)=>{
  return request({
    url: '/api/zt-pay/ztpay/tradeAll',
    method: 'post',
    params: {
      outTradeNo
    }
  })
}
// 对外支付信息获取接口
export const getTicketDetail = (ticketId)=>{
  return request({
    url: '/api/zt-pay/ztpay/selectInfo',
    method:'post',
    params:{
      ticketId
    }
  })
}
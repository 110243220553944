
import CryptoJS from 'crypto-js';

export default {
  // generatekey(num) {
  //   let library = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  //   let key = "";
  //   for (var i = 0; i < num; i++) {
  //     let randomPoz = Math.floor(Math.random() * library.length);
  //     key += library.substring(randomPoz, randomPoz + 1);
  //   }
  //   return key;
  // },

  //加密
  encrypt(word, keyStr) {
    //keyStr = keyStr ? keyStr : 'abcdsxyzhkj12345'; //判断是否存在ksy，不存在就用定义好的key
    var ivs = 'e3205234a872012a';
    var b = CryptoJS.enc.Base64
    var key = CryptoJS.enc.Utf8.parse(keyStr);
    var iv = CryptoJS.enc.Utf8.parse(ivs);
    var srcs = CryptoJS.enc.Utf8.parse(word);
    var encrypted = CryptoJS.AES.encrypt(srcs, key, {iv, mode: CryptoJS.mode.CBC, padding: CryptoJS.pad.Pkcs7 });
    return b.stringify(encrypted.ciphertext);
  },
  //解密
  decrypt(word, keyStr) {
    keyStr = keyStr ? keyStr : 'abcdsxyzhkj12345';
    var key = CryptoJS.enc.Utf8.parse(keyStr);
    var decrypt = CryptoJS.AES.decrypt(word, key, { mode: CryptoJS.mode.ECB, padding: CryptoJS.pad.NoPadding });
    return CryptoJS.enc.Utf8.stringify(decrypt).toString();
  }

}
